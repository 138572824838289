var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px" },
              attrs: { size: "small", placeholder: "昵称或ID" },
              model: {
                value: _vm.params.userName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "userName", $$v)
                },
                expression: "params.userName"
              }
            }),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            ),
            _c(
              "a-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.openModal(_vm.row)
                  }
                }
              },
              [_vm._v("添加灰名单")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确认要执行操作吗?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("取消拉灰")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 500 },
          on: { ok: _vm.checkForms, cancel: _vm.cancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "150px", "overflow-y": "auto" } },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.forms, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "用户id", prop: "userId" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入用户ID" },
                        model: {
                          value: _vm.forms.userId,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "userId", $$v)
                          },
                          expression: "forms.userId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "备注原因", prop: "remark" } },
                    [
                      _c("a-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入备注原因"
                        },
                        model: {
                          value: _vm.forms.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "remark", $$v)
                          },
                          expression: "forms.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }