<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="params.userName" size='small' style="width:150px;" placeholder='昵称或ID'></a-input>
                
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button> 

                <a-button @click="openModal(row)" size='small'>添加灰名单</a-button>
            </div>
        </div>
      
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">                
                
                <div slot="setup" slot-scope="row">
                    <a-popconfirm
                        title="确认要执行操作吗?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row)"
                    >
                    <a  style='margin-left:5px'>取消拉灰</a>
                    </a-popconfirm>
                    
                </div>
            </a-table>
        </div>

        <a-modal centered :width='500' v-model="showModal" @ok='checkForms' @cancel='cancel'>
            <div style="height:150px;overflow-y:auto;">
                <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
                    <a-form-model-item label='用户id' prop='userId'>
                        <a-input placeholder='请输入用户ID' v-model="forms.userId"></a-input>
                    </a-form-model-item> 

                    <a-form-model-item label='备注原因' prop='remark'>
                        <a-input type="textarea" placeholder='请输入备注原因' v-model="forms.remark"></a-input>
                    </a-form-model-item> 
              
                </a-form-model>
            </div>
        </a-modal>

    </div>
</template>

<script>
import { Button } from 'ant-design-vue'
import {mapState} from 'vuex'
export default {
    data(){
        return {
            columns:[
                {title: "用户Id",dataIndex: "userId"},
                {title: "昵称",dataIndex: "userName"},
                {title:'注册时间',align:'center',dataIndex:'createTime'},
                {title:'最近活跃时间',align:'center',dataIndex:'lastLoginTime'},
                {title:'备注原因',align:'center',dataIndex:'remark'},
                {title: "操作",align: "center",scopedSlots: { customRender: "setup" },},
            ],
            data:[],
            forms:{
                userId:'',
            },
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },    
            rules:{
                userId:[{ required: true, message: '用户ID', trigger: 'blur' }],
            },
            showModal:false,
            visible:false,
            params:{
                pageNum:1,
                pageSize:10,
                userName:'',
            },
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            clicked: false,
            hovered: false,
        }
    },
    
    computed:{
        ...mapState(['ip'])
    },
    created(){
        this.getData(this.params)
    },
    methods:{
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/userGray/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },
        search(){
            this.params.pageNum = 1
            this.getData(this.params)
        },
        
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.pageSize = pageSize
            this.getData(this.params)
        },
        openModal(row) {
            this.showModal = true;
        },
        cancel(){
            this.forms = {rankIcon:'',userId:''}
            this.$refs.ruleForm.resetFields();
        },
        checkForms(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        async submit(){
            const res = await this.axios.post('/dq_admin/userGray/add',this.forms)
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getData(this.params)
                this.showModal = false
                this.cancel()
            }
        },
        remove(row) {
            const userId = row.userId
            this.axios("/dq_admin/userGray/del", {
            params: { userId: userId },
        }).then((res) => {
            this.getData(this.params);

            this.$message.success(res.message);
            });
         },
         
        handleClickChange(visible) {
            this.clicked = visible;
            this.hovered = false;
        },
    }
}

function d(a,b){
    // setTimeout((
        
    // ),1000);
    // this.params.fansSort = 0
    this.getData(this.params)
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;max-height:700px;}
</style>